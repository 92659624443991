import { Navigate, useLocation } from 'react-router-dom';

export const PrivateRoute = ({ element: Element, ...rest }) => {
  const location = useLocation();
  const user = JSON.parse(localStorage.getItem('user'));
  const isAdmin = user?.isAdmin;

  if (!user) {
    return <Navigate to="/" state={{ from: location }} />;
  }

  return isAdmin ? (
    <Element {...rest} />
  ) : (
    <Navigate to="/home" state={{ from: location }} />
  );
};


export const PrivateRouteAdmin = ({ element: Element, ...rest }) => {
  const location = useLocation();
  const user = JSON.parse(localStorage.getItem('user'));
  const isAdmin = user?.isAdmin;

  if (!user) {
    return <Navigate to="/" state={{ from: location }} />;
  }

  return !isAdmin ? (
    <Element {...rest} />
  ) : (
      <Navigate to="/admin/users" state={{ from: location }} />
  );
};
