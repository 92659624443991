import { Routes, Route, HashRouter } from "react-router-dom";
import { lazy, Suspense } from "react";
import { PrivateRoute, PrivateRouteAdmin } from './Components/PrivateRoute';

const Login = lazy(() => wait(1000).then(() => import('./Components/Login')));
const Events = lazy(() => wait(1000).then(() => import('./Components/Events')));
const Home = lazy(() => wait(1000).then(() => import('./Components/Home')));
const Header = lazy(() => wait(1000).then(() => import('./Components/Header')));
const Users = lazy(() => wait(1000).then(() => import('./admin/Users')));
const Loader = lazy(() => wait(1000).then(() => import('./Components/Loader')));
const EventFormPage = lazy(()=>wait(1000).then(()=>import('./admin/EventFormPage')))

function App() {
  return (
    <>
      <HashRouter>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/event/:id" element={<PrivateRouteAdmin element={Events} />} />
            <Route path="/home" element={<PrivateRouteAdmin element={Home} />} />
            <Route path="/admin/users" element={<PrivateRoute element={Users} />} />
            <Route path="/events/add" element={<PrivateRoute element={EventFormPage} />} />
            <Route path="/events/edit/:id" element={<PrivateRoute element={EventFormPage} />} />
          </Routes>
        </Suspense>
      </HashRouter>
    </>
  );
}

function wait(time) {
  return new Promise(resolve => {
    setTimeout(resolve, time);
  });
}

export default App;
